<template>
    <div class="register-area pt-5">
      <div class="container">
        <div class="row g-4 g-lg-5 align-items-center justify-content-center">
          <div class="col-12 col-md-6 col-xl-6">
            <div class="register-card text-center">
              <h2>Create your free account</h2>
               <img class="reg-logo" src="~@/assets/img/trade-images/logregfor/reg.png">
              <p>Already have an account?<router-link class="ms-1 hover-primary" to="login">Log In</router-link></p>
              <div class="register-form mt-5">
                <form action="#" method="post" autocomplete="off" v-if="!responseMessage">
                  <div class="row">
                    
                    <div class="form-group mb-4">
                      
                      <input 
                        class="form-control" 
                        type="text" 
                        placeholder="First Name" 
                        required
                        v-model="user.first_name"
                        autocomplete="off"
                      >
                    </div>
                    
                  </div>
                  <div class="row">
                  <div class="form-group mb-4">
                    <input 
                        class="form-control" 
                        type="text" 
                        placeholder="Last Name" 
                        v-model="user.last_name" 
                        autocomplete="off"
                        
                      >
                  </div>
                  <div class="form-group mb-4">
                      <input class="form-control" type="email" placeholder="Email" v-model="user.email" autocomplete="off">
                    </div>
                  <div class="form-group mb-4">
                      
                      <label class="label-psswd" for="registerPassword" @click="toglePasswordView">Show</label>
                    <input class="form-control" id="registerPassword" :type="isPasswordView"  v-model="user.password" :placeholder="$t('registration.password')" autocomplete="off">
                    </div>
                  <div class="form-group mb-4">
                    <label class="label-psswd" for="registerPasswordConfirm" @click="toglePasswordView">Show</label>
                    <input class="form-control" id="registerPasswordConfirm" :type="isPasswordView"  v-model="user.password_confirmation" :placeholder="$t('registration.confirm_password')" autocomplete="off">
                  </div>
                  
                  </div>
                  
                  <div class="form-group mb-4">
                    <input class="form-control" type="text" :placeholder="$t('registration.tel_number')" v-model="user.phone" autocomplete="off">
                  </div>

                    <v-select
                      class="form-control"
                      label="name"
                      v-model="selectedCountry"
                      :options="countriesList"
                      :searchable="false"
                      :filterable="false"
                    ></v-select>
                    <v-select
                      class="form-control"
                      label="name"
                      v-model="selectedLeverage"
                      :options="[1, 5, 10, 20, 100]"
                    ></v-select>

                  <div class="form-check mb-4 text-left">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="user.agreerules"
                    />
                    {{ $t("registration.accept") }}
                    <router-link to="/privacy">{{
                      $t("registration.privacy_policy")
                    }}</router-link>
                  </div>
                  <div class="form-check mb-4 text-left">
                    <input type="checkbox" class="form-check-input" v-model="user.tooOld" />
                    {{ $t("registration.18_year_old") }}
                  </div>
                  <button class="btn btn-primary w-100" type="submit"  @click="submitRegistrationData($event)">Register Now</button>
                  
                </form>
                <div class="form-content lg-text fade show" v-if="responseMessage">
                    {{ responseMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import router from "@/router";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import { mapState } from "vuex";

export default {
  name: "Registration",
  components: {
    vSelect
  },
  data() {
    return {
      selectedCountry: {},
      selectedLeverage: 10,
      user: {
        agreerules: false,
        country: {
          code: null,
          name: ""
        },
        country_id: null,
        email: "",
        first_name: "",
        lang: "en",
        last_name: "",
        leverage: "",
        password: "",
        password_confirmation: "",
        phone: "",
        tooOld: false
      },
      responseMessage: "",
      isPasswordView: "password",
    };
  },
  computed: mapState(["countriesList"]),
  created() {
    this.$store.dispatch("getCountries");
  },
  methods: {
    submitRegistrationData(event) {
      event.preventDefault();
      event.stopPropagation();

      if (
        !this.user.email ||
        !this.user.first_name ||
        !this.user.last_name ||
        !this.user.password ||
        !this.user.password_confirmation ||
        !this.user.phone ||
        this.user.password.length < 6 ||
        this.user.email.length < 6 ||
        this.user.password != this.user.password_confirmation
      ) {
        return;
      }
      
      this.user.country.code = this.selectedCountry.id;
      this.user.country.name = this.selectedCountry.name;
      this.user.country_id = this.selectedCountry.id;
      this.user.leverage = "" + this.selectedLeverage;
      this.$store.dispatch("registration", this.user).then(() => {
        this.responseMessage = this.$i18n.messages[
          this.$i18n.locale
        ].registration.reg_s_message;
        setTimeout(function() {
          router.push("/login");
        }, 10000);
      });
    },
    toglePasswordView(){
      if(this.isPasswordView == "password"){
        this.isPasswordView = "text"
      }else {
        this.isPasswordView = "password"
      }
    }
  }
};
</script>

<style scoped>
.reg-logo {
  max-width: 50%;
}

.v-select.form-control {
  padding: 0 !important;
  height: auto;
  margin-bottom: 16px;
}

/deep/.v-select.form-control > div {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;

}

.text-left {
  text-align: left;
}

.lg-text {
  font-size: 25px;
}

#registerPassword,
#registerPasswordConfirm {
  color: #8480ae !important;
  border-color: #ebebeb !important;
  background-color: #efeffd !important;
  -webkit-tap-highlight-color: #efeffd !important;
}
</style>