<template>
  <div id="account-profile">
    <!-- <dashboard-header></dashboard-header> -->
    <div @click="clickHendler" class="menu-toggler ms-2 ms-sm-3" id="dashboardMenuTrigger"><i class="bi bi-grid"></i></div>
    <account-menu></account-menu>
    <div class="admin-wrapper">
      <router-view id="account-main"></router-view>
    </div>
  </div>
</template>

<script>
import DashboardHeader from "@/components/dashboard/header/DashboardHeader.vue";
import AccountMenu from "@/components/account/AccountMenu.vue";

export default {
  name: "AccountProfile",
  components: {
    DashboardHeader,
    AccountMenu
  },
  methods: {
    clickHendler(){
      //TODO: удалить этот. Реализовано для открытия меню по бургеру.
      document.querySelector('body').classList.toggle("dashboard-header-active");
    },
  },
};
</script>
<style scoped>
.admin-wrapper{
  padding-bottom: 0;
  margin-top: 12px;
}
@media(min-width: 992px){
  #dashboardMenuTrigger {
    display: none;
  }
}

#dashboardMenuTrigger {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 30px;
  color: #00c7b1;
  z-index: 9999;
}
div.velmld-overlay {
  z-index: 10 !important;
}
</style>