<template>
  <div id="private">
    <!-- <dashboard-header></dashboard-header> -->
    <div @click="clickHendler" class="menu-toggler ms-2 ms-sm-3" id="dashboardMenuTrigger"><i class="bi bi-grid"></i></div>
    <dashboard-side-menu></dashboard-side-menu>
    <router-view id="account-main"></router-view>
  </div>
</template>

<script>
import DashboardHeader from "@/components/dashboard/header/DashboardHeader.vue";
import DashboardSideMenu from "@/components/dashboard/side-menu/DashboardSideMenu.vue";

export default {
  name: "Dashboard",
  components: {
    DashboardHeader,
    DashboardSideMenu
  },
  created() {
    this.getAvailableCredit();
    this.checkIsLimitedAccess();
    this.getLastCreditDate();
  },
  watch:{
    $route: function (to, from) {
      if (to.path === '/terminal' && (from.path.includes('/terminal/') > -1))
      {
        this.$store.commit("RESET_CHART_KLINES");
      }
    }
  },
  methods: {
    clickHendler(){
      //TODO: удалить этот. Реализовано для открытия меню по бургеру.
      document.querySelector('body').classList.toggle("dashboard-header-active");
    },
    getAvailableCredit: function() {
      this.$store.dispatch("getAvailableCreditAmount");
    },
    checkIsLimitedAccess: function() {
      this.$store.dispatch("checkLimited");
    },
    getLastCreditDate() {
      this.$store.dispatch("getLastCreditDate").then(() => {
        this.$store.commit("SET_IS_CREDIT_DATE_LOADED", true);
      });
    }
  }
};
</script>

<style scoped>
.admin-wrapper{
  margin-top: 0;
}

@media(min-width: 992px){
  #dashboardMenuTrigger {
    display: none;
  }
}

#dashboardMenuTrigger {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 30px;
  color: #00c7b1;
  z-index: 9999;
}


</style>