import Vue from "vue";
import VueRouter from "vue-router";
import MainPage from "../views/layout/MainPage";
import FAQ from "../views/layout/FAQ";
import Markets from "../views/layout/Markets";
import MarketShares from "../views/layout/MarketShares";
import MarketIndices from "../views/layout/MarketIndices";
import MarketForex from "../views/layout/MarketForex";
import MarketCryptoCurrency from "../views/layout/MarketCryptoCurrency";
import MarketCommodities from "../views/layout/MarketCommodities";
import LearnToTrade from "../views/layout/LearnToTrade";
import EarningsCalendar from "../views/layout/EarningsCalendar";
import Careers from "../views/layout/Careers";
import Compliance from "../views/layout/Compliance";
import IsSafe from "../views/layout/IsSafe";
import AboutWhyUs from "../views/layout/AboutWhyUs";
import Login from "../views/layout/Login";
import EmailConfirmation from "../views/layout/EmailConfirmation";
import ChangePassword from "../views/layout/ChangePassword";
import Dashboard from "../views/dashboard/Dashboard";
import Registration from "../views/layout/Registration";
import Privacy from "../views/layout/Privacy";
import HomeLayout from "../views/layout/HomeLayout";
import DashboardMain from "../views/dashboard/DashboardMain";
import AccountProfile from "../views/account/AccountProfile";
import PersonalInfo from "../views/account/body/PersonalInfo";
import Withdraw from "../views/account/body/Withdraw";
import History from "../views/account/body/History";
import Balance from "../views/account/body/Balance";
import Statistic from "../views/account/body/Statistic";
import Notification from "../views/account/body/Notification";
import EconomicCalendar from "../views/layout/EconomicCalendar";
import DashboardCup from "../views/dashboard/body/DashboardCup";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: HomeLayout,
    children: [
      {
        path: "",
        name: "MainPage",
        component: MainPage
      },
      {
        path: "/login",
        name: "Login",
        component: Login
      },
      {
        path: "/registration",
        name: "Registration",
        component: Registration
      },
      {
        path: "/confirm-email/:token",
        name: "EmailConfirmation",
        component: EmailConfirmation
      },
      {
        path: "/change-password/:token",
        name: "ChangePassword",
        component: ChangePassword
      }
    ]
  },
  {
    path: "/terminal",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "Main",
        component: DashboardMain
      },
      {
        path: "forex",
        name: "Forex",
        component: DashboardCup
      },
      {
        path: "stock",
        name: "Stock",
        component: DashboardCup
      },
      {
        path: "indices",
        name: "Indices",
        component: DashboardCup
      },
      {
        path: "crypto",
        name: "Crypto",
        component: DashboardCup
      },
      {
        path: "metals",
        name: "metals",
        component: DashboardCup
      },
    ]
  },
  {
    path: "/account-profile",
    component: AccountProfile,
    children: [
      {
        path: "",
        name: "PersonalInfo",
        component: PersonalInfo
      },
      {
        path: "withdraw",
        name: "Withdraw",
        component: Withdraw
      },
      {
        path: "history",
        name: "History",
        component: History
      },
      {
        path: "balance",
        name: "Balance",
        component: Balance
      },
      {
        path: "statistic",
        name: "Statistic",
        component: Statistic
      },
      {
        path: "notification",
        name: "Notification",
        component: Notification
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

// TODO: implement router like the next example:

/*

import Vue from 'vue';
import VueRouter from 'vue-router';
import config from 'config';

Vue.use(VueRouter)

const router = new VueRouter({history: true, root: config.root});

router.map({
    '/': {
        name: 'home',
        component: view('home')
    },
    '/user/:oid/': {
        name: 'user',
        component: view('user')
    },
    '/post/new/': {
        name: 'new-post',
        component: view('post-wizard')
    },
    '/post/:oid/': {
        name: 'post',
        component: view('post')
    }
});


// Asynchronously load view (Webpack Lazy loading compatible)
// @param  {string}   name     the filename (basename) of the view to load.

function view(name) {
  return function(resolve) {
    require(['./views/' + name + '.vue'], resolve);
  }
};

export default router;

*/
