<template>
    <header class="header-area dashboard-header px-0 px-sm-0">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <div class="d-flex align-items-center">
            <!-- Brand Logo -->
            <div class="admin-logo me-1 me-sm-3"><img src="@/assets/img/core-img/dashboard-logo.png" alt=""></div>
          </div>
          <!-- Header Meta -->
          <div class="header-meta d-flex align-items-center">
            <!-- Notification -->
            <div class="user-dropdown dropdown mx-2 mx-sm-3">
              <button class="btn dropdown-toggle user-btn" id="dd10" type="button" data-bs-toggle="dropdown" aria-expanded="false"><img src="@/assets/img/core-img/notification.png" alt=""></button>
              <!-- <ul class="dropdown-menu noti-dd-menu dropdown-menu-end mt-3" aria-labelledby="dd10">
                <li><a class="dropdown-item" href="#"><i class="me-2 bi bi-percent"></i>You have an offer!</a></li>
                <li><a class="dropdown-item" href="#"><i class="bg-info me-2 bi bi-tags"></i>Congratulations! You sale an item.</a></li>
                <li><a class="dropdown-item" href="#"><i class="bg-danger me-2 bi bi-gift"></i>January freebies have arrived.</a></li>
                <li><a class="dropdown-item" href="#"><i class="bg-warning me-2 bi bi-star"></i>A new rating has been received.</a></li>
                <li><a class="dropdown-item justify-content-center" href="notifications.html">View all notifications</a></li>
              </ul> -->
            </div>
            <!-- User Dropdown -->
            <div class="user-dropdown dropdown">
              <button class="btn dropdown-toggle user-btn" id="dd20" type="button" data-bs-toggle="dropdown" aria-expanded="false"><img src="@/assets/img/core-img/user.png" alt=""></button>
              <!-- <ul class="dropdown-menu dropdown-menu-end mt-3" aria-labelledby="dd20">
                <li><a class="dropdown-item" href="dashboard.html"><i class="me-2 bi bi-person-circle"></i>Dashboard</a></li>
                <li><a class="dropdown-item" href="live-bids.html"><i class="me-2 bi bi-hammer"></i>My bids</a></li>
                <li><a class="dropdown-item" href="my-collection.html"><i class="me-2 bi bi-collection"></i>Collection</a></li>
                <li><a class="dropdown-item" href="settings.html"><i class="me-2 bi bi-gear"></i>Settings</a></li>
              </ul> -->
            </div>
            <!-- Menu Toggler -->
            <div @click="clickHendler" class="menu-toggler ms-2 ms-sm-3" id="dashboardMenuTrigger"><i class="bi bi-list"></i></div>
            <button 
              class="btn btn-sm btn-danger rounded-pill ms-2 ms-sm-3 d-none d-sm-block"
              @click="pushDepositPage()"
            ><i class="bi bi-eye me-1"></i>{{ $t("dashboard.add_balance") }}</button>
          </div>
        </div>
      </nav>
    </header>
</template>

<script>
import router from "@/router";
export default {
  name: "DashboardHeader",
  methods: {
    pushDepositPage() {
      router.push("/account-profile/withdraw");
    },
    clickHendler(){
      //TODO: удалить этот. Реализовано для открытия меню по бургеру.
      document.querySelector('body').classList.toggle("dashboard-header-active");
    },
  }
};
</script>

<style scoped>
#dashboardMenuTrigger {
  color: #00c7b1;
}

</style>